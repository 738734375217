<template>
  <both-colum-page
    :title="$t('templateManage.title')"
    :formData="formData"
    @functionMethod="functionMethod"
    @search="search"
  >
    <template v-slot:left-title>{{ $t('templateManage.category') }}</template>
    <template v-slot:left>
      <ul style="margin-top: 10px">
        <li>
          <el-button
              style="width: 100%; margin-top: 20px"
              :type="params.groupId === null ? 'primary' : ''"
              @click="selectTemplateType({value: null})"
          >
            {{$t('templateManage.all')}}
          </el-button>
        </li>
        <li v-for="item in templateTypeEnum" :key="item.value">
          <el-button
              style="width: 100%; margin-top: 20px"
              :type="item.value === params.groupId ? 'primary' : ''"
              @click="selectTemplateType(item)"
          >
            {{ item.label }}
          </el-button>
        </li>
      </ul>
    </template>
    <table-list
      ref="tableList"
      :url="'/' + $util.getLocalStorage('apiprefix') + $api.templateFindList"
      :multiple="true"
      :highlightCurrentRow="false"
      :columns="columns"
      :defaultParams="params"
      v-model="tableRows"
    >
      <!-- 模板图标 -->
      <template v-slot:coverImgId="{ row }">
        <el-image style="height: 80px" :fit="'contain'" v-if="row.coverImgFile" :src="row.coverImgFile.fileHost + '/' + row.coverImgFile.objectName" />
      </template>
      <!-- 操作 -->
      <template v-slot:option="{ row }">
        <!-- lang:修改 -->
        <el-button
          v-if="$page.showBtn('WORK_FLOW_STEP_TEMPLATE_MANAGE_UPDATE')"
          type="text"
          @click="editMethod(row)"
        >
          {{ $t('functionEnum.WORK_FLOW_STEP_TEMPLATE_MANAGE_UPDATE') }}
        </el-button>
        <!-- lang:删除 -->
        <el-button
            v-if="$page.showBtn('WORK_FLOW_STEP_TEMPLATE_MANAGE_DEL')"
            type="text"
            @click="deleteMethod(row)"
        >
          {{ $t('functionEnum.WORK_FLOW_STEP_TEMPLATE_MANAGE_DEL') }}
        </el-button>
        <!-- lang:显示 -->
        <el-button
            v-if="$page.showBtn('WORK_FLOW_STEP_TEMPLATE_MANAGE_VIEW')"
            type="text"
            @click="viewMethod(row)"
        >
          {{ $t('functionEnum.WORK_FLOW_STEP_TEMPLATE_MANAGE_VIEW') }}
        </el-button>
      </template>
    </table-list>
    <detail v-if="showDetail" :tableRow="tableRow" :groupId="params.groupId" @closePage="closePage" />
    <dialog-import
        v-if="showDialogImport"
        :url="'/' + $util.getLocalStorage('apiprefix') + $api.templateImport"
        :show-tips="false"
        :accept="'.template'"
        @cancel="showDialogImport = false"
        @confirm="importConfirm"
    />
  </both-colum-page>
</template>

<script>
import Detail from './detail'

export default {
  components: {
    Detail,
  },
  data() {
    let _that = this
    return {
      // 搜索
      formData: {},
      // 显示表单
      showDetail:false,
      showDialogImport:false,
      templateTypeEnum: null,
      // 表格页
      tableRow: null,
      tableRows: [],
      params: {
        companyUid: this.$store.getters.company.uid,
        groupId: 0,
      },
      columns: [
        // lang:模板名称
        {
          label: this.$t('templateManage.stepName'),
          prop: 'stepName',
        },
        // lang:模板编号
        {
          label: this.$t('templateManage.serialNumber'),
          prop: 'serialNumber',
        },
        // lang:模板类型
        {
          label: this.$t('templateManage.templateType'),
          prop: 'templateType',
        },
        // lang: 模板图标
        {
          label: this.$t('templateManage.coverImg'),
          prop: 'coverImgId',
        },
        // lang:操作
        {
          label: this.$t('base.option'),
          prop: 'option',
          width: '200px',
        },
      ],
    }
  },
  mounted() {
    this.dictFindTree()
  },
  methods: {
    selectTemplateType(item) {
      this.params.groupId = item.value
      this.search()
    },
    /**
     * 获取模板步骤类型枚举
     */
    dictFindTree() {
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.dictFindTree,
        data: {
          language: 'zhCN',
          dictType: 'WORK_FLOW_STEP_GROUP',
        },
      }).then(res => {
        this.templateTypeEnum = res.map(item => {
          return {value: item.id, label: item.value}
        })
        if(this.templateTypeEnum && this.templateTypeEnum[0]) {
          this.selectTemplateType(this.templateTypeEnum[0])
        }
      })
    },
    // 关闭详情页回调
    closePage(cmd) {
      this.showDetail = false
      this.showRenewal = false
      this.search()
    },
     // 权限按钮点击回掉
    functionMethod(item) {
      const { method } = item
      if (method !== 'import' && method !== 'add' && this.tableRows.length === 0) {
        this.$element.showMsg(this.$t('base.selectTableRow'), 'warning')
        return
      }
      switch (method) {
        case 'add':
          this.showDetail = true
          break
        case 'export':
          this.exportMethod()
          break
        case 'import':
          this.showDialogImport = true
          break
        default: // do something
      }
    },
    editMethod(row) {
      this.tableRow = row
      this.$store.state.cmd = 'update'
      this.showDetail = true
    },
    viewMethod(row) {
      this.tableRow = row
      this.$store.state.cmd = 'view'
      this.showDetail = true
    },
    exportMethod() {
      const ids = this.tableRows.map(item => item.id)
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.templateExport,
        data: {ids}
      }).then(res => {
        window.location.href = res
      })
    },
    // 导入成功回掉
    importConfirm() {
      this.showDialogImport = false
      this.search()
    },
    deleteMethod(row) {
      this.tableRow = row
      this.$element
          .showConfirm({
            content: this.$t('base.deleteConfirm'),
          })
          .then(() => {
            this.$ajax({
              url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.templateDel,
              data: {id:this.tableRow.id},
            }).then(() => {
              // lang:删除成功
              this.$element.showMsg(this.$t('base.deleteSuccess'), 'success')
              this.search()
            })
          })
    },
    // 查询按钮点击回调
    search(isActiveQuery) {
      const formData = this.$util.copyData(this.formData)
      this.$refs.tableList.searchList(formData, isActiveQuery)
    },
  },
}
</script>
